import { projectCostsDao } from '@/service';

export default {
  state: {
    goodsFiltered: [],
    response: {}
  },
  mutations: {
    SET_GOODS_FILTERED(state, goodsFiltered) {
      state.goodsFiltered = goodsFiltered;
    },
    SET_RESPONSE(state, response) {
      state.response = response;
    }
  },
  actions: {
    async getAllProjectCosts(_, payload) {
      const config = {
        params: {
          perPage: 1000,
          workspace_id: payload.workspace_id,
          inactive: payload.inactive,
          group: payload.group,
          expense: payload.expense
        },
      };
      return new Promise((resolve, reject) => {
        projectCostsDao
          .getAll(config)
          .getPage()
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async saveProjectCosts(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .store(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getProjectCosts(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .get(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getPastYearOfficeCost(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .getPastYearOfficeCost(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async updateProjectCosts(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .update(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async removeProjectCosts(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .deletee(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async activeCosts(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .activateCosts(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getCalcCosts(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .calc(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getInactivateProjectCosts(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .getAll({ active: false, ...payload })
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    saveGoodsFiltered({ commit }, goodsFiltered) {
      commit('SET_GOODS_FILTERED', goodsFiltered);
    },
    saveResponse({ commit }, response) {
      commit('SET_RESPONSE', response);
    },

  },

  getters: {
    getGoodsFiltered(state) {
      return state.goodsFiltered.length
        ? state.goodsFiltered
        : JSON.parse(localStorage.getItem('goodsFiltered')) || [];
    },
    getResponse(state) {
      return Object.keys(state.response).length
        ? state.response
        : JSON.parse(localStorage.getItem('response')) || {};
    }
  },
};
