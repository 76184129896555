import http from '../api';

let getAll = async (data) => {
  const response = await http.get(
    `projects/?workspace_id=${data.workspace_id}&archived=${data.archived}&page=${data.page}&perPage=${data.perPage}`
  );
  return response;
};
let get = async (data) => {
  const response = await http.get(
    `projects/${data.id}?workspace_id=${data.workspace_id}`,
    data
  );
  return response;
};
let store = (data) => {
  return http.post('projects', data);
};
let update = (data) => {
  return http.put(`projects/${data.id}`, data);
};

let dashboard = (data) => {
  return http.get(
    `projects-dashboard?workspace_id=${data.workspace_id}&project_id=${data.project_id}&start_date=${data.start_date}&end_date=${data.end_date}`
  );
};
// let activateCosts = (data) => {
//   return http.put(`activate-costs/${data.id}`, data);
// };

let deletee = (data) => {
  return http.delete(`projects?id=${data.id}&type=${data.type}`);
};

let unDelete = (data) => {
  return http.put(`projects-undelete?id=${data.id}`);
};

export default {
  getAll,
  store,
  get,
  update,
  dashboard,
  unDelete,
  //   activateCosts,
  deletee,
};
