import { costsCategoryDao } from '@/service';

export default {
  state: {},
  mutations: {},
  actions: {
    async getAllCostsCategory(_, payload) {
      const config = {
        params: {
          perPage: payload.perPage,
          page: payload.page,
          inactive: payload.inactive,
          workspace_id: payload.workspace_id,
          cost_category: payload.cost_category
        },
      };
      return new Promise((resolve, reject) => {
        costsCategoryDao
          .getAll(config)
          .getPage()
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getCostCategory(_, payload) {
      const config = {
        params: {
          id: payload.id,
        },
      };
      return new Promise((resolve, reject) => {
        costsCategoryDao
          .getOne(config)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async saveCostCategory(_, payload) {
      return new Promise((resolve, reject) => {
        costsCategoryDao
          .store(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async updateCostCategory(_, payload) {
      const config = {
        expense_category_name: payload.expense_category_name,
        office_cost: payload.office_cost,
        project_cost: payload.project_cost,
        id: payload.id,
      };
      return new Promise((resolve, reject) => {
        costsCategoryDao
          .update(config)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async removeCostCategory(_, payload) {
      return new Promise((resolve, reject) => {
        costsCategoryDao
          .deletee(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async activeCostCategory(_, payload) {
      return new Promise((resolve, reject) => {
        costsCategoryDao
          .activate(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  getters: {},
};
