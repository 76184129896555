//http://0.0.0.0:3343/v1/projects
import tasksDao from '@/service/tasks';

export default {
  state: {},
  mutations: {},
  actions: {
    async getMyTasks(_, payload) {
      return new Promise((resolve, reject) => {
        tasksDao
          .getMy(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async getMyBySelect(_, payload) {
      return new Promise((resolve, reject) => {
        tasksDao
          .getMyBySelect(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async getAllTasks(_, payload) {
      return new Promise((resolve, reject) => {
        tasksDao
          .getAll(payload)
          .then(async (resp) => {
            resolve(resp.data.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async getAllProjectTasks(_, payload) {
      return new Promise((resolve, reject) => {
        tasksDao
          .getAllProjectTasks(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async saveMyTask(_, payload) {
      return new Promise((resolve, reject) => {
        tasksDao
          .save(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async updateMyTask(_, payload) {
      return new Promise((resolve, reject) => {
        tasksDao
          .modify(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    async deleteTask(_, payload) {
      return new Promise((resolve, reject) => {
        tasksDao
          .destroy(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },

  getters: {},
};
